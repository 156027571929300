<template>
  <div class="portfolio">
    <!-- <div class="portfolio__hero">
      <figure class="portfolio__hero-image__wrapper">
        <img src="" alt="" class="portfolio__hero-image">
      </figure>
    </div>
    <div class="portfolio__content">
      <h1 class="portfolio__headline">Blue Ridge Mobile Autocare</h1>
      <p class="portfolio__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente corporis dolorum possimus fugit voluptatum accusantium!</p>
    </div> -->
    <div class="portfolio__card">
      <h3 class="portfolio__card-title">Blue Ridge Mobile Autocare</h3>
      <p class="portfolio__card-description">They had a problem, we provided the solution. Blue Ridge Mobile Autocare able to have a custom website designed and built while staying well within their budget.</p>
      <a href="https://blueridgemobileautocare.com/" target="_blank" class="portfolio__card-cta">Go to site &rarr;</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Portfolio",
};
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

.portfolio {
  @include page-animation;
  
  // &__hero {
  //   height: 300px;
  //   width: 100vw;

  //   @include desktop {
  //     height: 600px;
  //   }

  //   &-image__wrapper {
  //     width: 100%;
  //     height: 100%;
  //   }

  //   &-image {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     background-color: red;
  //   }
  // }

  // &__headline {
  //   font-size: $font-size-large;

  //   @include desktop {
  //     font-size: $font-size-x-large;
  //   }
  // }

  // &__content {
  //   padding: $page-padding-mobile;

  //   @include desktop {
  //     padding: $page-padding-desktop;
  //   }
  // }

  &__card {
    max-width: 80%;
    border: solid 1px $gray;
    margin: 50px auto 0;
    padding: 10px;
    text-align: left;

    @include desktop {
      max-width: 500px;
      width: 100%;
    }

    &-title {
      @include text-gradient;
      font-size: $font-size-medium;

      @include desktop {
        font-size: $font-size-large;
      }
    }

    &-description {
      margin: 20px 0;
    }

    &-cta {
      @include text-gradient;
      font-size: $font-size-medium;
    }
  }
}
</style>
