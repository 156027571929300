<template>
  <div class="about">
    <h1 class="about__headline">Welcome to the&nbsp;GRID</h1>
    <div class="about__content">
      <p class="about__text">
        GRID Studios is a creative agency dedicated to designing and building engaging
        digital experiences for small businesses. In today's tech savvy world, every business needs a website. However, having a custom website designed and built can be incredibly expensive.
      </p>
      <p class="about__text">
        GRID has a solution to this problem. You can rest assured that you will receive an affordable custom website. Please email us at <a href="mailto: hello@gridstudios.us" class="about__link">hello@gridstudios.us</a> to learn more.
      </p>
      <div class="about__services">
        <h3 class="about__subheadline">What do we do?</h3>
        <ul class="about__services">
          <li class="about__service"><span class="material-icons">design_services</span>Web Design</li>
          <li class="about__service"><span class="material-icons">code</span>Web Development</li>
          <li class="about__service"><span class="material-icons">home_repair_service</span>Website Repair</li>
        </ul>
      </div>
      <!-- <div class="about__packages">
        <h3 class="about__subheadline">Packages</h3>

      </div> -->
      <router-link to="/connect" class="about__link about__link--connect">
        Request a quote &rarr;
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
};
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.material-icons {
  @include text-gradient;
  font-size: $font-size-medium;
  margin-right: 20px;

  @include desktop {
    font-size: $font-size-large;
  }
}

.about {
  text-align: left;
  padding: $page-padding-mobile;
  max-width: 100vw;
  @include page-animation;

  @include desktop {
    max-width: $page-width-desktop;
    padding: unset;
    margin: 0 auto;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }

  &__headline {
    font-size: $font-size-x-large;
    margin: 0 auto;
    @include geo-font;
    @include text-gradient;

    @include desktop {
      font-size: $font-size-xx-large;
    }
  }

  &__content {
    padding-left: 10px;
    max-width: 800px;
    letter-spacing: $letter-spacing;
  }

  &__subheadline {
    margin: 40px 0 30px;
    font-size: $font-size-medium;
    @include text-gradient;

    @include desktop {
      font-size: $font-size-large;
    }
  }

  &__text {
    line-height: $line-height;
    font-weight: 600;
    font-size: $font-size-small;
    margin-top: 15px;
    @include roboto-font;
    text-decoration: none;

    &--gradient {
      @include text-gradient;
      font-size: $font-size-medium;
      line-height: unset;  
      text-transform: uppercase;
      @include geo-font;

      @include desktop {
        font-size: $font-size-large;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__link {
    color: $blue;
    margin-bottom: 20px;

    &:hover {
      @include text-gradient;
      transition: .5s all;
    }

    &--connect {
      color: $blue;
      font-size: $font-size-medium;
      text-decoration: none;

      @include desktop {
        font-size: $font-size-large;
      }

      &:hover {
        @include text-gradient;
        transition: .5s all;
        text-decoration: underline;
      }
    }
  }

  &__services {
    list-style: none;
    margin-bottom: 40px;
  }

  &__packages {

  }

  &__service {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-size: $font-size-medium;

    @include desktop {
      font-size: $font-size-large;
    }
  }
}
</style>