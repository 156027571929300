<template>
  <div class="connect">
    <h1 class="connect__headline">Let's Connect!</h1>
    <p class="connect__subheadline">Please fill out the form below. We look forward to hearing from you!</p>
    <form ref="form" @submit.prevent="sendEmail" class="connect__contact-form">
    <div class="connect__contact-form__group">
      <label class="connect__contact-form__label">First name</label>
      <input class="connect__contact-form__input" type="text" name="first_name" placeholder="First name">
    </div>
    <div class="connect__contact-form__group">
      <label class="connect__contact-form__label">Last name</label>
      <input class="connect__contact-form__input" type="text" name="last_name" placeholder="Last name">
    </div>
    <div class="connect__contact-form__group">
      <label class="connect__contact-form__label">Company</label>
      <input class="connect__contact-form__input" type="text" name="company" placeholder="Company">
    </div>
    <div class="connect__contact-form__group">
      <label class="connect__contact-form__label">Email</label>
      <input class="connect__contact-form__input" type="email" name="email" placeholder="Email">
    </div>
    <div class="connect__contact-form__group">
      <label class="connect__contact-form__label">Message</label>
      <textarea class="connect__contact-form__input connect__contact-form__input--textarea" name="message"></textarea>
    </div>
    <p class="connect__contact-form__confirmation">{{ formConfirmation }}</p>
    <input class="connect__contact-form__button" type="submit" value="Send">
  </form>
  </div>
</template>
<script>
import emailjs from 'emailjs-com';

export default {
  name: "Connect",
  data() {
    return {
      formConfirmation: ''
    }
  },
  methods: {
    sendEmail() {
      emailjs.sendForm('service_u5a3n0j', 'template_6jz7ksq', this.$refs.form, 'user_0EZvEIyZhzaPXTeEU9ddt')
        .then((result) => {
            console.log('SUCCESS!', result.text);
            this.formConfirmation = 'Thank you! We will reach out to you soon.'
            console.log(this.formConfirmation)
            this.$refs.form.reset()
        }, (error) => {
            console.log('FAILED...', error.text);
            this.formConfirmation = 'There was an error. Please try again.'
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/css/global-styles.scss";

.connect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: $page-padding-mobile;
  @include page-animation;

  @include desktop {
    padding: $page-padding-desktop;
  }

  &__headline {
    @include text-gradient;
    font-size: $font-size-large;

    @include desktop {
      font-size: $font-size-xx-large;
    }
  }

  &__contact-form {
    display: flex;
    flex-direction: column;
    max-width: $contact-form-width;
    width: 90%;
    margin: 50px auto 0;
    text-align: left;

    @include desktop {
      width: 100%;
    }

    &__group {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
    }

    &__label {
      padding-bottom: 5px;
      font-weight: bold;
      font-size: $font-size-medium;
    }

    &__input {
      height: 30px;
      padding: 5px 10px;
      font-size: $font-size;
      @include roboto-font;
      background-color: transparent;
      border: solid 1px $dark-gray;
      color: $white;
      border-radius: 5px;

      &--textarea {
        height: 100px;
      }
    }

    &__button {
      width: 100px;
      border: none;
      background-color: $black;
      color: $white;
      border: solid 1px $white;
      padding: 10px;
      border-radius: 5px;
      font-size: $font-size-medium;
      @include geo-font;
      font-weight: bold;
      transition: .8s all;
      cursor: pointer;

      &:hover {
        border: solid 1px $blue;
        @include text-gradient;
      }
    }

    &__confirmation {
      font-size: $font-size-medium;
      margin: 5px 0 15px;
      @include text-gradient;

      @include desktop {
        font-size: $font-size-large;
      }
    }
  }
}
</style>
